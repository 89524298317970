<template>
	<div class="role-management">
		<h1>Edit Role</h1>
		<!-- GET ROLE FORM -->
		<AddRoleForm></AddRoleForm>
	</div>
	<!-- end container -->
</template>
<script>
import AddRoleForm from "../../components/forms/AddRoleForm.vue"

export default {
	name: "roleManagement",
	data() {
		return {}
	},
	components: {
		AddRoleForm,
	},
	methods: {},
}
</script>
